var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('transition',{attrs:{"name":"fade"}},[(_vm.error && _vm.error.message)?_c('div',{staticClass:"col-xl-12"},[_c('div',{staticClass:"alert alert-danger w-100",attrs:{"role":"alert"}},[_c('span',{staticClass:"alert-main-text"},[_vm._v("Error!")]),_vm._v(" "+_vm._s(_vm.error.message)+" ")])]):_vm._e()]),_c('div',{class:{
      'col-xl-9  mb-2 p-2': _vm.format != 'compact',
      'col-xl-12': _vm.format == 'compact',
    },attrs:{"id":_vm.session.SessionCode}},[_c('p',[_vm._v(_vm._s(_vm.session.SessionCode + " - " + _vm.session.Name))]),(_vm.format != 'compact')?_c('session-time-string',{attrs:{"session":_vm.session}}):_vm._e()],1),(_vm.session.MaxCredits != 0)?_c('div',{class:{
      'col-xl-3': _vm.format != 'compact',
      'col-xl-12': _vm.format == 'compact',
    }},[(!_vm.confirmDelete)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12 col-6 p-1"},[_c('button',{staticClass:"btn btn-primary-solid w-100 mb-2",on:{"click":function($event){_vm.addOrEditNavigation(
              _vm.doesSessionHaveClaim(_vm.session.SessionCode) ? 'Edit' : 'Add'
            )}}},[_vm._v(" "+_vm._s(_vm.doesSessionHaveClaim(_vm.session.SessionCode) ? "Edit" : "Add")+" ")])]),_c('div',{staticClass:"col-lg-12 col-6 p-1"},[(_vm.doesSessionHaveClaim(_vm.session.SessionCode))?_c('a',{staticClass:"btn btn-warning-solid w-100 mb-2",attrs:{"href":"#/"},on:{"click":function($event){return _vm.confirmDeleteCME(_vm.session.SessionCode)}}},[_vm._v(" Delete ")]):_vm._e()])]):_c('div',{staticClass:"row",class:{ 'col-xl-12': _vm.format == 'compact' }},[_c('div',{staticClass:"col-lg-12 col-md-6 p-1"},[_c('button',{staticClass:"btn btn-warning-solid w-100 mb-2 px-0",on:{"click":function($event){return _vm.deleteCME(_vm.session.SessionCode)}}},[_vm._v(" Confirm Delete ")])]),_c('div',{staticClass:"col-lg-12 col-md-6 p-1"},[_c('a',{staticClass:"btn btn-primary-solid w-100 mb-2 px-0",attrs:{"href":"#/"},on:{"click":function($event){return _vm.cancelDelete()}}},[_vm._v(" Cancel ")])])])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }